import { useFormik } from "formik";
import React, { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Yup from "yup";
import {
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import dayjs from "dayjs";
import "./schedule-custom.scss";

const DateTimeModal = ({
  handleDateTimeModal,
  setStartDate,
  setEndDate,
  open,
  fetchSchedule,
  selectedRadio,
  setSelectedRadio,
  startDate,
  endDate,
}) => {
  useEffect(() => {
    if (selectedRadio === "week") {
      const startOfWeek = dayjs().startOf("week").format("MM-DD-YYYY");
      const endOfWeek = dayjs().endOf("week").format("MM-DD-YYYY");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    } else if (selectedRadio === "month") {
      const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = dayjs().endOf("month").format("YYYY-MM-DD");
      setStartDate(startOfMonth);
      setEndDate(endOfMonth);
    }
  }, [selectedRadio]);

  const formik = useFormik({
    initialValues: {
      start_date:
        selectedRadio === "specific" ? startDate : dayjs().format("MM-DD-YYYY"), // Set current date as default
      end_date:
        selectedRadio === "specific" ? endDate : dayjs().format("MM-DD-YYYY"), // Set current date as default
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.date().required("Start Date is required"),
      end_date: Yup.date()
        .required("End Date is required")
        .when("start_date", (start_date, schema) => {
          return schema.min(start_date, "End Date can't be before Start Date");
        }),
    }),
    onSubmit: async (values) => {
      fetchSchedule();
      handleCloseSave();
    },
  });

  const handleRadioChange = (event) => {
    if (event.target.value === "today") {
      const today = dayjs().format("MM-DD-YYYY");
      setStartDate(today);
      setEndDate("");
    } else if (event.target.value === "week") {
      const startOfWeek = dayjs().startOf("week").format("MM-DD-YYYY");
      const endOfWeek = dayjs().endOf("week").format("MM-DD-YYYY");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    } else if (event.target.value === "specific") {
      const startOfWeek = dayjs().format("MM-DD-YYYY");
      const endOfWeek = dayjs().format("MM-DD-YYYY");
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
  };

  const handleCloseSave = () => {
    handleDateTimeModal();
    formik.resetForm();
  };

  return (
    <Modal
      className="date-time-modal"
      open={open}
      onClose={() => {
        handleCloseSave();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">Filter By</h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1
                onClick={() => {
                  handleCloseSave();
                }}
              />
            </div>
          </div>
          <div className="modal_content">
            <div className="row m-0">
              <div className="item_increase p-0">
                <div className="row m-0">
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    className="mb-3"
                  >
                    <b>Date</b>
                  </FormLabel>
                  <RadioGroup
                    className="p-0"
                    aria-labelledby="demo-radio-buttons-group-label "
                    defaultValue={selectedRadio}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="today"
                      control={<Radio />}
                      label="Today"
                      onChange={(e) => {
                        handleRadioChange(e);
                        setSelectedRadio("today");
                      }}
                    />
                    <FormControlLabel
                      value="week"
                      control={<Radio />}
                      label="This Week"
                      onChange={(e) => {
                        handleRadioChange(e);
                        setSelectedRadio("week");
                      }}
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio />}
                      label="This Month"
                      onChange={(e) => {
                        handleRadioChange(e);
                        setSelectedRadio("month");
                      }}
                    />
                    <FormControlLabel
                      value="specific"
                      control={<Radio />}
                      label="Specific Date Range"
                      className="mb-3"
                      onChange={(e) => {
                        handleRadioChange(e);
                        setSelectedRadio("specific");
                      }}
                    />
                  </RadioGroup>

                  {selectedRadio == "specific" && (
                    <>
                      <div className="col-md-12 p-0">
                        <div className="form_field_wrapper">
                          <label>
                            Start Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dayjs(formik.values?.start_date)}
                              onChange={(newStartDate) => {
                                formik.setFieldValue(
                                  "start_date",
                                  dayjs(newStartDate).format("MM-DD-YYYY")
                                );
                                setStartDate(
                                  dayjs(newStartDate).format("MM-DD-YYYY")
                                );
                              }}
                            />
                          </LocalizationProvider>
                          {formik.touched.start_date &&
                          formik.errors.start_date ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.start_date}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12 p-0">
                        <div className="form_field_wrapper">
                          <label>
                            End Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dayjs(formik.values?.end_date)}
                              onChange={(newEndDate) => {
                                formik.setFieldValue(
                                  "end_date",
                                  dayjs(newEndDate).format("MM-DD-YYYY")
                                );
                                setEndDate(
                                  dayjs(newEndDate).format("MM-DD-YYYY")
                                );
                              }}
                              minDate={dayjs(formik.values?.start_date)}
                            />
                          </LocalizationProvider>
                          {formik.touched.end_date && formik.errors.end_date ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.end_date}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <button
              type="submit"
              onClick={(e) => {
                setStartDate("");
                setEndDate("");
                fetchSchedule();
                setSelectedRadio("week");
              }}
              className="btn btn-border"
            >
              Reset
            </button>

            <button type="submit" className="btn btn-primary">
              Apply
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DateTimeModal;
