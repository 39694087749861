import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import "./ViewDetails.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption, Encryption } from "../../../Mixin/EncryptDecryptId";
import dayjs from "dayjs";
import FileDisplay from "../../FileInput/FileDisplay";
import ReactStars from "react-rating-stars-component";
import TaskView from "../../Task/View/View";
import WorkTask from "../../WorkTask/View/View";
import FilePreview from "../../FilePreview/FilePreview";

const ViewDetails = () => {
  const params = useParams();
  const { id } = params || {};
  const [ViewDetails, setViewDetails] = useState([]);
  const [rating, setRating] = useState();
  const [apComment, setAPComment] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const decryptedId = Decryption(id);
  const location = useLocation();
  const prev_id = location?.state?.prev_id; // Retrieve prev_id from location state
  const task_type = location?.state?.task_type; // Retrieve prev_id from location state
  const [openModal, setOpenModal] = useState(false);
  const fetchViewDetails = () => {
    setLoading(true);
    httpService
      .get(`schedule/get_schedule_task_image/${decryptedId}`)
      .then((response) => {
        setViewDetails(response?.data?.data);
        setRating(response?.data?.rating);
        setAPComment(response?.data?.reason);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchViewDetails();
  }, [params?.id, showLoginPopup]);

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    if (location.state.from === "dashboard") {
      navigate(`/${location.state.from}`);
    } else {
      navigate(`/schedule/view/${urlEncodedId}`, {
        state: { from: location.state?.from },
      });
    }
  };
  const ImageSection = ({ images, index }) => (
    <>
      {images &&
        images?.map(({ title, beforeStatus, afterStatus }) => {
          return (
            <div className="main-title" key={`${title}_${index}`}>
              <div className="row mb-4">
                {beforeStatus?.length > 0 && (
                  <div className="col-6">
                    <div className="col-md-12">
                      <div className="d-flex gap-2">
                        <h4>{`${title} Before Status`}</h4>
                      </div>
                    </div>
                    <div className="col-md-12 images-before-name">
                    <div onClick={() => setOpenModal(true)}>
                      <FileDisplay attached={beforeStatus} Tag={"span"} />
                      </div>
                    </div>
                  </div>
                )}

                {afterStatus?.length > 0 && (
                  <div className="col-6">
                    <div className="col-md-12">
                      <div className="d-flex gap-2">
                        <h4>{`${title} After Status`}</h4>
                      </div>
                    </div>
                    <div className="col-md-12 images-before-name">
                      <div onClick={() => setOpenModal(true)}>
                      <FileDisplay attached={afterStatus} Tag={"span"} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      <hr />
    </>
  );

  const images = [
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
  ];

  const paths = () => {
    if (!Array.isArray(ViewDetails) || ViewDetails.length === 0) {
      return [];
    }
  
    const imagePaths = ViewDetails.flatMap(item => {
      return [
        ...(item?.root_image_before_status || []),
        ...(item?.root_image_after_status || []),
        ...(item?.top_image_before_status || []),
        ...(item?.top_image_after_status || []),
        ...(item?.side_image_before_status || []),
        ...(item?.side_image_after_status || []),
      ].filter(path => path);
    });
  
    return imagePaths;
  }


  return (
    <>
      {loading && <FullScreenLoader />}
      {ViewDetails?.length === 0 ? (
        task_type === 'task' || !task_type ? <TaskView /> : <WorkTask />
      ) : (
        <div className="maintenance-view">
          <div className="page-title">
            <div className="title-wrap">
              <h1>
                <span className="back">
                  <BsArrowLeft onClick={() => viewPage(prev_id)} />
                </span>
                Task Details
                {ViewDetails?.length > 0
                  ? " - " + `[${ViewDetails[0]?.task_name}]`
                  : ""}
              </h1>
            </div>
          </div>
          {/* {ViewDetails?.length === 0 && (
            <h3>
              <center>No Data Found</center>
            </h3>
          )} */}
          {(rating || apComment) && (
            <div className="rating_section">
              <div className="row">
                <div className="col-md-4">
                  <div className="date_block rate_block">
                    <label>Approver Comment : </label>
                    <span className="rate_star">{apComment}</span>
                  </div>
                  <div className="date_block rate_block">
                    <label>Ratings : </label>
                    <span className="rate_star">
                      <ReactStars
                        count={5}
                        size={24}
                        activeColor="#ffd700"
                        value={parseInt(rating)}
                        edit={false}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {ViewDetails &&
            ViewDetails?.map((item, index) => (
              <>
                <div className="date_block_wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="date_block">
                        <label>Start Date : </label>{" "}
                        {dayjs
                          .utc(item.start_task_datetime)
                          .format("MMM/DD/YYYY h:mm A")}
                      </div>
                      <div className="date_block">
                        <label>End Date : </label>{" "}
                        {dayjs
                          .utc(item.end_task_datetime)
                          .format("MMM/DD/YYYY h:mm A")}
                      </div>
                      <div className="date_block">
                        <label>Comment : </label> {item.comment || "  --  "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="date_block">
                        <label>Activity Name : </label>{" "}
                        {item.activity_name || "  --  "}
                      </div>
                      <div className="date_block">
                        <label>Milestone Name : </label>{" "}
                        {item.milestone_name || "  --  "}
                      </div>
                      <div className="date_block">
                        <label>Critical Comment : </label>{" "}
                        {item.describe_error || "  --  "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="date_block">
                        <label>Employee Names : </label>{" "}
                        {item.employee_names || "  --  "}
                      </div>
                      <div className="date_block">
                        <label>Task Name : </label> {item.task_name || "  --  "}
                      </div>
                    </div>
                  </div>
                </div>
                <ImageSection
                  images={[
                    {
                      title: "Root Image",
                      beforeStatus: item?.root_image_before_status || [],
                      afterStatus: item?.root_image_after_status || [],
                    },
                    {
                      title: "Side Image",
                      beforeStatus: item?.side_image_before_status || [],
                      afterStatus: item?.side_image_after_status || [],
                    },
                    {
                      title: "Top Image",
                      beforeStatus: item?.top_image_before_status || [],
                      afterStatus: item?.top_image_after_status || [],
                    },
                  ]}
                  index={index}
                />
              </>
            ))}
        </div>
      )}
      {
      <FilePreview images={paths()} openModal={openModal} setOpenModal={(e) => setOpenModal(e)}/>
      }
    </>
  );
};

export default ViewDetails;
