import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { useEffect, useState } from "react";
import { HttpService } from "../../../service/HttpService";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import FileInput from "../../FileInput/FileInput";
import { useAuth } from "../../../context/AuthProvider";
import { splitDuration } from "../../../Mixin/DisplayDuration";
import SelectInput from "../../SelectInput/SelectInput";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddEditWorkTask = ({
  open,
  handleClose,
  task_id,
  isAdd,
  companyId,
  locationId,
  fetchTask,
}) => {
  const [milestoneNames, setMilestoneNames] = useState([]);
  const [activityNames, setActivityNames] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [apiError, setApiError] = useState("");
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [showOngoingField, setshowOngoingField] = useState(false);

  const autoSequenceOrder = async (payload) => {
    return await httpService
      .post("work-task/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          formik.setFieldValue("sort_order", response?.data?.max_sort_order);
        } else {
          formik.setFieldValue("sort_order", "");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  useEffect(() => {
    fetchCompanies();
    if (companyId > 0) {
      getLocationByCompany(companyId);
    }
    if (locationId > 0) {
      getMilestoneByLocation(locationId);
    }
  }, [companyId, locationId, showLoginPopup]);

  useEffect(() => {
    if (task_id !== "") {
      httpService.get(`work-task/get_worktask/${task_id}`).then((response) => {
        const { days, hours, minutes } =
          splitDuration(
            response?.data[0].days,
            response?.data[0].hours,
            response?.data[0].minutes
          ) || {};

        formik.setValues({
          name: response?.data[0].name,
          durationDays: days || 0,
          durationHours: hours || 0,
          durationMinute: minutes || 0,
          company: response?.data[0].company_id,
          location: response?.data[0].location_id,
          activity: response?.data[0].activity_id || "",
          milestone: response?.data[0].milestone_id || "",
          description: response?.data[0].description,
          attached: response?.data[0].attachment || [],
          sort_order: response?.data[0].sort_order || "",
          min: response?.data[0].min_employee || "",
          max: response?.data[0].max_employee || "",
          version: response?.data[0].version || "",
          task_type: response?.data[0].task_type || "work_task",
          on_going: response?.data[0].on_going || false,
          cron_duration: response?.data[0].cron_duration || "",
          cron_week_days: response?.data[0].cron_week_days || [],
          cron_frequency: response?.data[0].cron_frequency || "",
          // cron_start_date:
          //   dayjs(response?.data[0].cron_start_date).format(
          //     "MM-DD-YYYY hh:mm a"
          //   ) || dayjs().format("MM-DD-YYYY hh:mm a"),
          // cron_end_date:
          //   dayjs(response?.data[0].cron_end_date).format(
          //     "MM-DD-YYYY hh:mm a"
          //   ) || dayjs().format("MM-DD-YYYY hh:mm a"),
        });
        if (response) {
          if (response?.data[0].milestone_id) {
            getActivityByMilestone([response?.data[0].milestone_id]);
          }
          if (response?.data[0].company_id) {
            getLocationByCompany(response?.data[0].company_id);
          }
          if (response?.data[0].location_id) {
            getMilestoneByLocation(response?.data[0].location_id);
          }
        }
      });
    } else {
      formik.resetForm();
    }
  }, [task_id]);

  const fetchCompanies = () => {
    httpService
      .get("company/get_companies")
      .then((response) => {
        setCompanies(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleChangeLocationMilestoneActivity = (key, id) => {
    if (key === "company") {
      // Call the API to fetch location data based on the selected company_id
      getLocationByCompany(id);
      formik.setFieldValue("location", "");
      formik.setFieldValue("milestone", "");
      formik.setFieldValue("activity", "");
      setLocations([]);
      setMilestoneNames([]);
      setActivityNames([]);
    }

    if (key === "location") {
      // Call the API to fetch milestone data based on the selected location_id
      getMilestoneByLocation(id);
      formik.setFieldValue("milestone", "");
      formik.setFieldValue("activity", "");
      setMilestoneNames([]);
      setActivityNames([]);
    }

    if (key === "milestone") {
      formik.setFieldValue("activity", "");
      getActivityByMilestone([id]);
    }
  };

  const getLocationByCompany = (id) => {
    return httpService
      .get(`location/get_location/company/${id}`)
      .then((response) => {
        setLocations(response?.data);
        if (response?.data.length === 0) {
          formik.setFieldValue("location", "");
        }
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const getMilestoneByLocation = async (id) => {
    try {
      const response = await httpService.get(
        `milestone/location/get_milestones/${id}`
      );
      setMilestoneNames(response?.data);
      if (response?.data.length === 0) {
        formik.setFieldValue("milestone", "");
      }
    } catch (error) {
      console.error("Error fetching milestone data:", error);
    }
  };

  const getActivityByMilestone = (id) => {
    return httpService
      .post(`activity/get_activity/milestone`, { milestone_id: id })
      .then((response) => {
        setActivityNames(response);
        if (response.length === 0) {
          formik.setFieldValue("activity", "");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleCloseSave = () => {
    formik.resetForm();
    handleClose();
    setLocations([]);
    setActivityNames([]);
  };

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("work-task/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      durationDays: "",
      durationHours: "",
      durationMinute: "",
      activity: "",
      milestone: "",
      company: (companyId > 0 && companyId) || "",
      location: (locationId > 0 && locationId) || "",
      description: "",
      file: [],
      attached: [],
      sort_order: "",
      min: "",
      max: "",
      version: "",
      task_type: "work_task",
      on_going: false,
      cron_duration: "",
      cron_week_days: [],
      cron_frequency: "",
      // cron_start_date: dayjs  ().format("MM-DD-YYYY hh:mm a"),
      // cron_end_date: dayjs().format("MM-DD-YYYY hh:mm a"),
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please Enter Task Name")
        .max(200, "Task Name must be less than 200 characters"),
      company: Yup.string().required("Please Select Company"),
      location: Yup.string().required("Please Select Location"),
      activity: Yup.string()
        // .min(1, "Please Select at least one Activity")
        .required("Please Select Activity"),
      milestone: Yup.string()
        // .min(1, "Please Select at least one Milestone")
        .required("Please Select Milestone"),
      durationDays: Yup.number().nullable(),
      durationHours: Yup.number().nullable().max(24, "Maximum 24 hours"),
      durationMinute: Yup.number().nullable().max(60, "Maximum 60 minutes"),
      description: Yup.string().max(
        2000,
        "Description must be less than 2000 characters"
      ),
      min: Yup.number()
        .typeError("Min. Employee must be a number") // Error message for non-numeric input
        .integer("Min. Employee must be an integer") // Ensure min is an integer
        .min(0, "Min. Employee must be greater than or equal to 0"), // Ensure min is greater than or equal to 0
      max: Yup.number()
        .typeError("Max. Employee must be a number") // Error message for non-numeric input
        .integer("Max. Employee must be an integer") // Ensure max is an integer
        .min(
          Yup.ref("min"),
          "Max. Employee must be greater than or equal to Min. Employee"
        ), // Ensure max is greater than or equal to min

      file: Yup.mixed()
        // .required("Attachment Is Required")
        .test("fileSize", "Attachment size must be less than 10MB", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          return files.every((file) => file.size <= 10485760); // Check size of each file
        })
        .test("fileFormat", "Invalid file format", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          if (files.length === 0) return true; // Skip if no files provided
          const allowedExtensions = {
            video: ["mp4", "webm", "mov"],
            pdf: ["pdf"],
            document: ["doc", "docx"],
            spreadsheet: ["xls", "xlsx", "csv"],
            image: ["jpg", "png", "jpeg", "gif", "webp"],
          };
          return files.every((file) => {
            if (!file || !file.name) return false; // If file or file name is undefined, return false
            const fileExtension = file?.name?.split(".").pop().toLowerCase();
            const fileType = Object.keys(allowedExtensions).find((type) =>
              allowedExtensions[type].includes(fileExtension)
            );
            return !!fileType;
          });
        }),
    }).test("at-least-one", null, (values) => {
      const { durationDays, durationHours, durationMinute } = values;
      if (!durationDays && !durationHours && !durationMinute) {
        return new Yup.ValidationError(
          "Please Enter One Duration",
          null,
          "durationDays"
        );
      }
      if (durationDays < 0 || durationHours < 0 || durationMinute < 0) {
        return new Yup.ValidationError(
          "Please Enter Valid Duration",
          null,
          "durationDays"
        );
      }
      return true;
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("days", values.durationDays || 0);
      formData.append("hours", values.durationHours || 0);
      formData.append("minutes", values.durationMinute || 0);
      formData.append("activity_id", values.activity);
      formData.append("milestone_id", values.milestone);
      formData.append("company_id", values.company);
      formData.append("location_id", values.location);
      formData.append("description", values.description || "");
      formData.append("sort_order", values.sort_order);
      formData.append("min_employee", parseInt(values.min) || "");
      formData.append("max_employee", parseInt(values.max) || "");
      formData.append("version", values.version || "");
      formData.append("task_type", values.task_type);
      formData.append("on_going", values.on_going);
      formData.append("cron_duration", values.cron_duration);
      formData.append("cron_frequency", values.cron_frequency);
      // formData.append("cron_start_date", values.cron_start_date);
      // formData.append(
      //   "cron_end_date",
      //   values.on_going === false ? values.cron_end_date : ''
      // );
      if (values.cron_week_days && values.cron_week_days.length > 0) {
        for (let i = 0; i < values.cron_week_days.length; i++) {
          formData.append(`cron_week_days[${i}]`, values.cron_week_days[i]);
        }
      } else {
        formData.append("cron_week_days", []);
      }
      if (values.file && values.file.length > 0) {
        for (let i = 0; i < values.file.length; i++) {
          formData.append(`attachment`, values.file[i]);
        }
      } else {
        formData.append("attachment", []);
      }
      if (values.attached) {
        if (task_id !== "" && !isAdd) {
          for (let i = 0; i < values.attached.length; i++) {
            formData.append(`existFile[${i}]`, values.attached[i]);
          }
        }
      }
      if (!apiError) {
        try {
          if (isAdd) {
            const response = await httpService.post(
              "work-task/add_worktask",
              formData
            );
            if (response) {
              toast.success("Work Task Added Successfully", {
                position: "top-right",
              });
              formik.resetForm();
              handleClose();
              fetchTask();
            }
          } else {
            const res = await httpService.put(
              `${"work-task/edit_worktask"}/${task_id}`,
              formData
            );
            if (res) {
              toast.success("Work Task Updated Successfully", {
                position: "top-right",
              });
              handleClose();
              fetchTask();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (
      formik.values?.sort_order === "" &&
      companyId > 0 &&
      locationId > 0 &&
      task_id === ""
    ) {
      autoSequenceOrder(
        { company_id: companyId, location_id: locationId },
        formik.setFieldValue
      );
    }
  }, [companyId, locationId, formik.values.sort_order]);

  const currentDate = dayjs();

  return (
    <Modal
      className="task-modal"
      open={open}
      onClose={() => {
        handleCloseSave();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">
                {isAdd === true ? "Add Work Task" : "Edit Work Task"}
              </h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1
                onClick={() => {
                  handleCloseSave();
                }}
              />
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="item_increase">
                <div className="row">
                  {/* <div className="col-md-12">
                    <div className="col-md-6">
                      <SelectInput
                        label="Task Type"
                        name="task_type"
                        value={formik.values.task_type || "work_task"}
                        options={[
                          { name: "Task", id: "task" },
                          { name: "Work Task", id: "work_task" },
                        ]}
                        multiple={false}
                        onChange={(event) => {
                          formik.setFieldValue("task_type", event.target.value);
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        error={formik.touched.task_type && formik.errors.task_type}
                        required={true}
                        selectAll={false}
                        displayEmpty
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form_field_wrapper">
                      <label>
                        Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        id="name"
                        InputProps={{}}
                        placeholder="Enter Your Task Name"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: "red" }}>{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <label>
                        Task Duration<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        type="number"
                        id="durationDays"
                        InputProps={{ inputProps: { min: 0 } }}
                        placeholder="DD"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.durationDays}
                      />
                      {formik.touched.durationDays &&
                      formik.errors.durationDays ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.durationDays}
                        </div>
                      ) : null}
                      {formik.touched.durationHours &&
                      formik.errors.durationHours ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.durationHours}
                        </div>
                      ) : null}
                      {formik.touched.durationMinute &&
                      formik.errors.durationMinute ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.durationMinute}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <label>&nbsp;</label>
                      <TextField
                        type="number"
                        id="durationHours"
                        InputProps={{ inputProps: { min: 0 } }}
                        placeholder="HH"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.durationHours}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <label>&nbsp;</label>
                      <TextField
                        type="number"
                        id="durationMinute"
                        InputProps={{ inputProps: { min: 0 } }}
                        placeholder="MM"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.durationMinute}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <SelectInput
                      label="Company"
                      name="company"
                      value={formik.values.company}
                      options={companies}
                      multiple={false}
                      onChange={(event) => {
                        formik.setFieldValue("company", event.target.value);
                        handleChangeLocationMilestoneActivity(
                          "company",
                          event.target.value
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.company && formik.errors.company}
                      required={true}
                      displayEmpty
                      disabled={companyId > 0 ? true : false}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectInput
                      label="Location"
                      name="location"
                      value={formik.values.location}
                      options={locations}
                      multiple={false}
                      onChange={(event) => {
                        formik.setFieldValue("location", event.target.value);
                        handleChangeLocationMilestoneActivity(
                          "location",
                          event.target.value
                        );
                        const payload = {
                          company_id: formik.values?.company,
                          location_id: event.target.value,
                          sort_order: formik.values?.sort_order,
                          id: task_id,
                        };
                        if (
                          formik.values?.company &&
                          formik.values?.sort_order &&
                          event.target.value
                        ) {
                          matchSequenceOrder(payload);
                        } else {
                          setApiError("");
                        }
                        if (task_id === "") {
                          autoSequenceOrder(payload);
                        }
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.location && formik.errors.location}
                      required={true}
                      displayEmpty
                      disabled={locationId > 0 ? true : false}
                    />
                  </div>

                  <div className="col-md-6">
                    <SelectInput
                      label="Milestone"
                      name="milestone"
                      value={formik.values.milestone}
                      options={milestoneNames}
                      multiple={false}
                      onChange={(event) => {
                        formik.setFieldValue("milestone", event.target.value);
                        handleChangeLocationMilestoneActivity(
                          "milestone",
                          event.target.value
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={
                        formik.touched.milestone && formik.errors.milestone
                      }
                      required={true}
                      displayEmpty
                    />
                  </div>

                  <div className="col-md-6">
                    <SelectInput
                      label="Activity"
                      name="activity"
                      value={formik.values.activity}
                      options={activityNames}
                      multiple={false}
                      onChange={(event) => {
                        formik.setFieldValue("activity", event.target.value);
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.activity && formik.errors.activity}
                      required={true}
                      displayEmpty
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="form_field_wrapper">
                      <label>Sequence(#)</label>
                      <TextField
                        name="sort_order"
                        // className={classes.textField}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        placeholder="Enter Sequence No."
                        value={formik.values.sort_order}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "sort_order",
                            event.target.value
                          );
                          if (
                            formik.values?.company &&
                            formik.values?.location &&
                            event.target.value
                          ) {
                            const payload = {
                              company_id: formik.values?.company,
                              location_id: formik.values?.location,
                              sort_order: event.target.value,
                              id: task_id,
                            };
                            matchSequenceOrder(payload);
                          } else {
                            setApiError("");
                          }
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {apiError && (
                        <span style={{ color: "red" }}>{apiError}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 employee_count">
                    <label>Employees</label>
                    <div className="row">
                      <div className="col-md-6 employee_item">
                        <div className="form_field_wrapper">
                          <label>Min</label>
                          <TextField
                            name="min"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            placeholder="Enter Min Emp"
                            value={formik.values.min}
                            onChange={(event) => {
                              formik.setFieldValue("min", event.target.value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 employee_item">
                        <div className="form_field_wrapper">
                          <label>Max</label>
                          <TextField
                            name="max"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            placeholder="Enter Max Emp"
                            value={formik.values.max}
                            onChange={(event) => {
                              formik.setFieldValue("max", event.target.value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      {formik.touched.min && formik.errors.min ? (
                        <div style={{ color: "red" }}>{formik.errors.min}</div>
                      ) : null}
                      {formik.touched.max && formik.errors.max ? (
                        <div style={{ color: "red" }}>{formik.errors.max}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.on_going}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "on_going",
                                  !formik.values.on_going
                                );
                              }}
                            />
                          }
                          label="OnGoing"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form_field_wrapper">
                        <label>Duration</label>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="cron_duration"
                            style={{ width: "100%" }}
                            value={formik.values.cron_duration || ""}
                            onChange={(event) => {
                              const value = event.target.value;
                              formik.setFieldValue(`cron_duration`, value);
                            }}
                            placeholder="Select Duration"
                            inputProps={{ "aria-label": "Select Duration" }}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              Select Duration
                            </MenuItem>
                            <MenuItem value={"weekly"}>Weekly</MenuItem>
                            <MenuItem value={"monthly"}>Monthly</MenuItem>
                            <MenuItem value={"yearly"}>Yearly</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {formik.values.cron_duration === "weekly" && (
                      <div className="col-md-4">
                        <div className="form_field_wrapper">
                          <label>Week Days</label>
                          <FormControl fullWidth>
                            <Select
                              name="cron_week_days"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              multiple
                              value={formik.values.cron_week_days || []}
                              onChange={(event) => {
                                const value = event.target.value;
                                let newValue;
                                if (value.includes("selectAll")) {
                                  newValue =
                                    formik.values.cron_week_days?.length === 7
                                      ? []
                                      : [
                                          "sun",
                                          "mon",
                                          "tue",
                                          "wed",
                                          "thurs",
                                          "fri",
                                          "sat",
                                        ];
                                } else {
                                  newValue = value;
                                }
                                formik.setFieldValue(
                                  `cron_week_days`,
                                  newValue
                                );
                              }}
                              displayEmpty
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <span>Select Days</span>;
                                }
                                return selected
                                  .map(
                                    (day) =>
                                      day.charAt(0).toUpperCase() + day.slice(1)
                                  )
                                  .join(", ");
                              }}
                              MenuProps={{
                                getcontentanchorel: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                keepMounted: true,
                              }}
                              inputProps={{ "aria-label": "Select Days" }}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value="" disabled>
                                Select Days
                              </MenuItem>
                              <MenuItem value="selectAll">
                                <Checkbox
                                  checked={
                                    formik.values.cron_week_days?.length === 7
                                  }
                                  indeterminate={
                                    formik.values.cron_week_days?.length > 0 &&
                                    formik.values.cron_week_days?.length < 7
                                  }
                                />
                                <ListItemText primary="Select All" />
                              </MenuItem>
                              {[
                                "sun",
                                "mon",
                                "tue",
                                "wed",
                                "thurs",
                                "fri",
                                "sat",
                              ].map((day, index) => (
                                <MenuItem key={index} value={day}>
                                  <Checkbox
                                    checked={
                                      formik.values.cron_week_days?.indexOf(
                                        day
                                      ) > -1
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      day.charAt(0).toUpperCase() + day.slice(1)
                                    }
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )}
                    <div className="col-md-4">
                      <div className="form_field_wrapper">
                        <label>Frequency</label>
                        <TextField
                          id="Frequency"
                          name="cron_frequency"
                          type="number"
                          placeholder="Enter cron_frequency"
                          onChange={formik.handleChange}
                          variant="outlined"
                          onBlur={formik?.handleBlur}
                          value={formik?.values.cron_frequency}
                        />
                        {formik?.errors.cron_frequency &&
                          formik?.touched.cron_frequency && (
                            <span style={{ color: "red" }}>
                              {formik?.errors.cron_frequency}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>Cron Start Date</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="cron_start_date"
                            className="text-filed-wrap date_field"
                            value={dayjs(formik.values?.cron_start_date)}
                            onChange={(date) => {
                              const formattedDate = date
                                ? date.format("MM-DD-YYYY")
                                : null;
                              formik.setFieldValue(
                                `cron_start_date`,
                                formattedDate
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            minDate={
                              dayjs(formik.values?.cron_start_date) ||
                              currentDate
                            }
                          />
                        </LocalizationProvider>
                        {formik.touched?.["cron_start_date"] &&
                          formik.errors?.["cron_start_date"] && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {formik.errors?.["cron_start_date"]}
                            </FormHelperText>
                          )}
                      </div>
                    </div>
                    {formik.values.on_going !== true && (
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Cron End Date</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              name="cron_end_date"
                              className="text-filed-wrap date_field"
                              value={dayjs(formik.values?.cron_end_date)}
                              onChange={(date) => {
                                const formattedDate = date
                                  ? date.format("MM-DD-YYYY")
                                  : null;
                                formik.setFieldValue(
                                  `cron_end_date`,
                                  formattedDate
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              minDate={
                                dayjs(formik.values?.cron_start_date) ||
                                currentDate
                              }
                            />
                          </LocalizationProvider>
                          {formik.touched?.["cron_end_date"] &&
                            formik.errors?.["cron_end_date"] && (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                {formik.errors?.["cron_end_date"]}
                              </FormHelperText>
                            )}
                        </div>
                      </div>
                    )}
                  </div> */}
                  <div className="col-md-6">
                    <div className="form_field_wrapper">
                      <label>Reff Doc. Version(s)</label>
                      <TextField
                        name="Reff Doc. Version(s)"
                        type="text"
                        placeholder="Enter Version(s)"
                        value={formik.values.version}
                        onChange={(event) => {
                          formik.setFieldValue("version", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <label>Description</label>
                      <div>
                        <TextareaAutosize
                          id="description"
                          className="w-100 text-area"
                          aria-label="Type Description"
                          minRows={3}
                          placeholder="Type Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <FileInput
                    file={formik.values.file}
                    attachment={formik.values.attached}
                    handleBlur={formik.handleBlur}
                    name="file"
                    name2="attached"
                    setFieldValue={(fieldName, value) =>
                      formik.setFieldValue(fieldName, value)
                    }
                  />
                  {formik.errors.file && formik.touched.file && (
                    <span style={{ color: "red" }}>{formik.errors.file}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <button
              onClick={() => {
                handleCloseSave();
              }}
              className="btn btn-border"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {isAdd === true ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddEditWorkTask;
